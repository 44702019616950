import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"hebrew\",\"latin\"]}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/initDatadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/onUser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/providers/toastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/vercel/path0/src/lib/queryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/tolgee/altProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TolgeeNextProvider"] */ "/vercel/path0/src/lib/tolgee/client.tsx");
