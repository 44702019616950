"use client";

import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { onIdTokenChanged } from "firebase/auth";
import { filterStandardClaims } from "next-firebase-auth-edge/lib/auth/claims";
import { auth } from "@/lib/firebase";
import { User as FirebaseUser, IdTokenResult } from "firebase/auth";
import { datadogRum } from "@datadog/browser-rum";
import {
    DD_PRESENTATION_COUNT,
    DD_SYLLABUS_COUNT,
    DD_VIDEO_COUNT,
} from "./initDatadog";

interface CustomUser {
    uid: string;
    displayName?: string;
    email?: string;
    customClaims: any;
}

function toUser(user: FirebaseUser, idTokenResult: IdTokenResult): CustomUser {
    return {
        uid: user.uid,
        displayName: user.displayName ?? undefined,
        email: user.email ?? undefined,
        customClaims: filterStandardClaims(idTokenResult.claims),
    };
}

export default function OnUser() {
    const [user, setUser] = useState<CustomUser | null>(null);

    const handleIdTokenChanged = async (firebaseUser: FirebaseUser | null) => {
        if (firebaseUser) {
            const idTokenResult = await firebaseUser.getIdTokenResult();
            setUser(toUser(firebaseUser, idTokenResult));
            return;
        }

        setUser(null);
    };

    useEffect(() => {
        return onIdTokenChanged(auth, handleIdTokenChanged);
    }, []);

    useEffect(() => {
        if (user) {
            LogRocket.identify(user.uid, {
                name: user.displayName ?? "",
                email: user.email ?? "",
            });
            datadogRum.setUser({
                id: user.uid,
                name: user.displayName,
                email: user.email,
                [DD_PRESENTATION_COUNT]: 0,
                [DD_SYLLABUS_COUNT]: 0,
                [DD_VIDEO_COUNT]: 0,
            });
        }
    }, [user]);

    return <></>;
}
