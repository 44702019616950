"use client";

import { T } from "@tolgee/react";
import { Toaster, ToastBar, resolveValue, Toast } from "react-hot-toast";

interface ExtendedToast extends Toast {
    keyName?: string;
}

export default function ToastProvider() {
    return (
        <Toaster>
            {(t: Toast) => {
                const extendedToast = t as ExtendedToast;
                const message = resolveValue(t.message, t);

                const keyName = extendedToast.keyName;

                return (
                    <ToastBar toast={t}>
                        {({ icon }) => (
                            <div className="flex items-center gap-3 py-1 px-2">
                                {icon}
                                {keyName ? (
                                    <T
                                        keyName={keyName}
                                        defaultValue={String(message)}
                                    >
                                        {String(message)}
                                    </T>
                                ) : (
                                    String(message)
                                )}
                            </div>
                        )}
                    </ToastBar>
                );
            }}
        </Toaster>
    );
}
