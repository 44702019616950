import { hasPermission } from "@/features/admin/utils";
import { User } from "@/features/auth/types";
import { BackendFetch } from "@tolgee/react";
import { Tolgee, FormatSimple } from "@tolgee/web";

export const ALL_LOCALES = ["he-IL"];

export const DEFAULT_LOCALE = "he-IL";

export async function getStaticData(languages: string[]) {
    const result: Record<string, any> = {};
    for (const lang of languages) {
        result[lang] = (await import(`../../../messages/${lang}.json`)).default;
    }
    return result;
}

export function TolgeeBase(user: User | null) {
    const tolgee = Tolgee().use(FormatSimple());

    if (user && hasPermission(user, "Translations") && user.tolgeeApiKey) {
        tolgee.updateDefaults({
            apiKey: user.tolgeeApiKey,
            apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
        });
    }

    if (process.env.NEXT_PUBLIC_ENV === "prod") {
        tolgee.use(
            BackendFetch({
                prefix: process.env.NEXT_PUBLIC_TOLGEE_CDN_URL,
            })
        );
    }

    return tolgee;
}
