"use client";

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { domains } from "./domains/domains";

type PropagatorType = "tracecontext" | "datadog";

if (process.env.NEXT_PUBLIC_ENV !== "local") {
    datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: process.env.NEXT_PUBLIC_DD_SITE,
        service: process.env.NEXT_PUBLIC_DD_APPLICATION_NAME,
        env: process.env.NEXT_PUBLIC_ENV,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: "all",
    });

    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: process.env.NEXT_PUBLIC_DD_SITE,
        service: process.env.NEXT_PUBLIC_DD_APPLICATION_NAME,
        env: process.env.NEXT_PUBLIC_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
        allowedTracingUrls: [
            ...domains.dev.urls.map((url) => ({
                match: url,
                propagatorTypes: [
                    "tracecontext",
                    "datadog",
                ] as PropagatorType[],
            })),
            ...domains.prod.urls.map((url) => ({
                match: url,
                propagatorTypes: [
                    "tracecontext",
                    "datadog",
                ] as PropagatorType[],
            })),
        ],
    });
}

export const DD_PRESENTATION_COUNT = "presentation_count";
export const DD_SYLLABUS_COUNT = "syllabus_count";
export const DD_VIDEO_COUNT = "video_count";

export default function InitDatadog() {
    // Render nothing - this component is only included so that the init code
    // above will run client-side
    return null;
}
